import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { HubbleLensesCta } from "../components/HubbleHero"
import VideoModule from "../components/VideoModule"
import Publications from "../components/Publications"
import HowHubbleWorksCarousel from "../components/HowHubbleWorksCarousel"
import ValuePropsSection from "../components/ValuePropsSection"
import HomeCta from "../components/HomeCta"
import ReviewCarousel from "../components/ReviewCarousel"
import ContactsSection from "../components/ContactsSection"
import HubbleSlider from "../components/HubbleSlider"
import { usePromo, promoStart, promoEnd } from "../utils/usePromo"

import SEO from "../components/seo"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../styles/pages/home-page.scss"
import OurContactLenses from "../components/OurContactLenses"
import LensesCta from "../components/LensesCta"
import HomePageGlassesCtaV2 from "../components/HomePageGlassesCtaV2"
import RecommendedList from "../components/RecommendedList"
import { useWindowWidth } from '../services/hooks'
import ContactsCartCta from "../components/ContactsCartCta";
import HubbleHeroPromo from "../components/HubbleHeroPromo";
import NewButton from "../components/NewButton";
import {navigate} from "gatsby";
import {hubbleClassic} from "../services/data";

const IndexPage = ({ data }) => {

  const schemaMarkup = [
    {
      "@context": "http://www.schema.org",
      "@type": "Organization",
      name: "Hubble",
      url: "https://hubblecontacts.com",
      sameAs: [
        "https://www.facebook.com/HubbleContacts/",
        "https://twitter.com/hubblecontacts",
        "https://www.instagram.com/hubblecontacts/",
      ],
      logo:
        "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg",
      image:
        "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg",
      description:
        "The more affordable daily contact lens. We founded Hubble with a simple mission: offer contact lens wearers the high quality and affordable lenses they deserve.",
      contactPoint: [
        {
          "@type": "ContactPoint",
          telephone: "+1-844-334-1640",
          contactType: "customer service",
        },
      ],
    },
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      name: "Hubble Contacts - We Make Ordering Contact Lenses Online Easy",
      description:
        `With no-fuss daily contact lens subscription personalization, home delivery, and hassle-free policies, Hubble makes ordering prescription daily contact lenses online easy. Why Hubble Contacts? Affordable daily contact lens subscriptions start at just $1. Pay $1 for your first ${hubbleClassic.trialPeriodPairs} pairs of our Classic contact lenses – or try Hydro hydrating contact lenses or breathable SkyHy by Hubble contacts made of silicone hydrogel for 40% off your first order. All Hubble prescription contact lenses are daily-wear contacts – the healthiest and safest type of contact lens. Over 400 million prescription contacts sold worldwide. You can stop shopping around. We’ll be your single point of contacts.`,
      thumbnailUrl: "https://i.ytimg.com/vi/MRO_-HrfY70/hqdefault.jpg",
      uploadDate: "2023-03-20",
      duration: "PT2M17S",
      contentUrl: "https://www.hubblecontacts.com",
      embedUrl: "https://www.youtube.com/watch?v=fOfjklHg5wA&t=6s",
    },
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      name: "Hubble",
      url: "https://hubblecontacts.com",
      sameAs: [
        "https://www.facebook.com/HubbleContacts/",
        "https://twitter.com/hubblecontacts",
        "https://www.instagram.com/hubblecontacts/",
      ],
      potentialAction: {
        "@type": "SearchAction",
        target:
          "https://hubblecontacts.com/pages/search_results?q={search_term}",
        "query-input": "required name=search_term",
      },
    },
    {
      "@context": "https://schema.org",
      "@url": "https://www.hubblecontacts.com",
      "@type": "webpage",
      name:
        "Hubble Contacts: Start Your Prescription Contact Lens Subscription & Order Prescription Glasses Online",
      description:
        "Hubble is the best place to buy affordable daily contact lenses and glasses online. Personalize your prescription contact lens subscription starting at $1.",
      publisher: {
        "@type": "Organization",
        name: "Hubble",
      },
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: [
        {
          "@type": "Question",
          name:
            "How does getting a Hubble subscription online for prescription daily contact lenses work?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              "Hubble handles your subscription of prescription daily contact lenses in just a few easy steps. 1 You sign up - Choose the Hubble daily contact lens product that fits your needs. We contact your eye doctor - Hubble will let you know via email when everything is all set. 2 We ship your contacts - Hubble preps, packs, and ships your prescription daily contact lenses right to your door in about 5 to 10 business days. 3 You take control - Personalize your contact lens subscription online to get your daily contact lenses when you need them. 4 We keep them coming - Your contacts are now set to arrive like clockwork. This is where you really see the benefit of your daily contact lens subscription.",
          },
        },
        {
          "@type": "Question",
          name:
            "Can I really start a prescription contact lens subscription for $1?",
          acceptedAnswer: {
            "@type": "Answer",
            text:
              `Yes, at Hubble you can order the affordable daily contact lenses Classic Contacts starter kit online for just $1 and free shipping! Pay $1 for your first ${hubbleClassic.trialPeriodPairs} of our Classic contact lenses – or try Hydro hydrating contact lenses or breathable SkyHy by Hubble contacts made of silicone hydrogel for 40% off your first order.`,
          },
        },
      ],
    },
  ]

  const [userHasScrolled, setUserHasScrolled] = useState(false)
  const windowWidth = useWindowWidth()
  const [isMobile, setIsMobile] = useState()

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", () => setUserHasScrolled(true), {
      once: true,
    })


  }

  useEffect(() => {
    setIsMobile(windowWidth < 767)
}, [windowWidth])


  const isPromo = usePromo(promoStart, promoEnd)

  const HomePageSliderV2 = () => (
    <HubbleSlider isPaginationHidden={isMobile}>
      {[
        isPromo && <HubbleHeroPromo/>,
        <HubbleLensesCta product="newPrices" homePageHero/>,
        <HubbleLensesCta product="lenses" homePageHero/>,
        !isPromo && <HubbleLensesCta product="astigmatism" homePageHero/>,
        <HubbleLensesCta product="frames" homePageHero/>
      ].filter(Boolean)}
    </HubbleSlider>
  )

  return (
     <Layout className="home-page">
      <SEO
        title="Order Prescription Contacts Online: Start Your Affordable Daily Contact Lens Subscription | Hubble"
        description="Hubble is the best place to order prescription contact lenses online. Save money with our personalized contact lens subscription, and shop for disposable lenses, eyeglasses, sunglasses, and more for your complete vision needs. "
        schemaMarkup={schemaMarkup}
      />
      <HomePageSliderV2 />
      <OurContactLenses isHomePageV2 />
      {isMobile && <NewButton
        label="Start now for as little as $1"
        color="purple"
        onClick={() => navigate("/contact-lenses/hubble")}
        className="homepage-cta"
        stickyScroll={true} />}
      {userHasScrolled && (
        <>
          <HowHubbleWorksCarousel />
          <Publications isHomePageV2/>
          <LensesCta product="skyhy" homepageCta/>
          <ValuePropsSection isHomePageV2/>
          <HomeCta />
          <HomePageGlassesCtaV2 />
          <RecommendedList />
          <VideoModule
            video={"https://www.youtube.com/embed/fOfjklHg5wA"}
            alt_text="Woman unboxes her Hubble contacts"
          />
          <ReviewCarousel color={"green"} page="homepage" isHomePageV2 />
          <ContactsSection isHomePage />
        </>
      )}
    </Layout>
  )
}

export default IndexPage
