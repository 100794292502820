import React, { useState, useRef } from "react"
import "./style.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/pro-regular-svg-icons"
import SliderPagination from "../SliderPagination"
import { reviews } from "./mockData.js"
import useIsHomePageV2 from "../../utils/useIsHomePageV2.js"

const ReviewCarousel = ({ color, page, isHomePageV2 }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const currentReviews = isHomePageV2 ? reviews.homepageV2 : reviews[page]
  const allPages = currentReviews?.length

  const reviewSliderRef = useRef()

  const settings = {
    adaptiveHeight: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    draggable: false,
    arrows: false,
    infinite: false,
    beforeChange: (prev, next) => {
      const newCurrentPage = next > prev ? currentPage + 1 : currentPage - 1

      setCurrentPage(newCurrentPage || 1)
    },
  }

  return (
    <div className="grid-section" id="reviews">
      <div className={`review-carousel ${color} ${isHomePageV2 ? "v2" : ""}`}>
        <div className={`top-line-wrapper ${page}`}>
          {page === "homepage" && <h3>See for Yourself</h3>}
          {page === "contacts" && (
            <h3>Happy customers as far as the eye can see.</h3>
          )}
          {page === "glasses" && (
            <h3>Happy customers as far as the eye can see.</h3>
          )}
          {page === "accessory" && (
            <h3>Happy customers as far as the eye can see.</h3>
          )}

          {page === "contacts" && (
            <p className="subheader">
              Over 400+ million prescription contacts sold. Hear what they have
              to say:
            </p>
          )}
          {page === "homepage" && (
            <p className="subheader">
              Hubble has helped over 1 million people simplify how they order
              contact lenses online. Here’s what they’re saying:
            </p>
          )}
          {page === "glasses" && (
            <p className="subheader">Hear what they have to say:</p>
          )}
          {page === "accessory" && (
            <p className="subheader">Hear what they have to say:</p>
          )}
        </div>
        <Slider
          className="carousel-container"
          ref={reviewSliderRef}
          prevArrow={<FontAwesomeIcon icon={faAngleLeft} />}
          nextArrow={<FontAwesomeIcon icon={faAngleRight} />}
          {...settings}
        >
          {currentReviews.map(review => {
            return (
              <div className="review-holder">
                <p className="review-copy">{review.review_copy}</p>
                <p className="stars">&#9733; &#9733; &#9733; &#9733; &#9733;</p>
                <p className="review-name">{review.reviewer}</p>
              </div>
            )
          })}
        </Slider>
        <SliderPagination
          isReviews
          sliderRef={reviewSliderRef}
          currentPage={currentPage}
          allPages={allPages}
          color={color}
        />
      </div>
    </div>
  )
}

export default ReviewCarousel
